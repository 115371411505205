/* eslint-disable import/no-unresolved */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from '@rails/activestorage';
import 'channels';

ActiveStorage.start();

$.ajaxSetup({ cache: false, dataType: 'JSON' });
